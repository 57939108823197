import { Box } from "@mui/system";
import { listTextStyle } from "../style";
import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import styled from "styled-components";

export const Modal = ({ state, setState }) => {
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState(open);
    };
    return (
        <>
            <img
                src="img/utils/menu.png"
                style={{ width: "40px", height: "40px" }}
                onClick={toggleDrawer(true)}
                alt="Menu"
            />
            <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: "100vw" }}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <DrawerTop>
                        {/* <img src="img/section1/core.png" /> */}
                        <h1
                            style={{
                                fontSize: "25px",
                                fontWeight: "800",
                            }}
                        >
                            COREDOT
                        </h1>
                        <img
                            src="img/utils/close.png"
                            style={{ width: "40px", height: "40px" }}
                            onClick={toggleDrawer(false)}
                            alt="Close"
                        />
                    </DrawerTop>
                    <List>
                        {["COREDOT", "MISSIONS1", "COMMUNITY", "CONTACT"].map(
                            (text) => (
                                <a href={`#${text}`}>
                                    <ListItem
                                        sx={{ marginTop: "15px" }}
                                        key={text}
                                        disablePadding
                                    >
                                        <ListItemButton
                                            sx={{ textAlign: "center" }}
                                            disableRipple
                                        >
                                            <ListItemText
                                                primaryTypographyProps={
                                                    listTextStyle
                                                }
                                                primary={
                                                    text === "MISSIONS1"
                                                        ? "MISSIONS"
                                                        : text
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </a>
                            )
                        )}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

const DrawerTop = styled.div`
    position: absolute;

    width: 100%;
    top: 30px;

    & * {
        margin: 0px 30px;
    }
    & img {
        width: 150px;
        position: absolute;
        top: 1px;
    }
    & h1 {
        position: absolute;
        top: -3.5px;
    }
    & img:first-child {
        left: -10px;
    }
    & img:last-child {
        top: -10px;
        right: -10px;
    }
`;
