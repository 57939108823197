import reset from "styled-reset";
import { createGlobalStyle, keyframes } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    ${reset}
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;800&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&family=Poppins:wght@100;200;400;800&display=swap');
    a {
        color: #fff; text-decoration: none; 
        outline: none;
        -webkit-tap-highlight-color:transparent;
        &:hover, &:active {text-decoration: none; color:#fff;}
    }

    body {
        color: white;
        background-color:black;
        /* font-family: 'Poppins', sans-serif; */
        font-family: 'Open Sans', sans-serif;
    }

    .MuiPaper-root {
        background-color: rgba(0,0,0,0.5) !important ;
        color:white !important;
        display:flex;
        align-items: center;
        justify-content:center;
        flex-direction:column;
    }

    .vsc-controller {
      display: none !important;
    }
`;

export const styleData = {
  bgColor: "rgb(0, 0, 0)",
  mobileWidth: "820px",
};

export const floatingAnimi = keyframes`

    0%{opacity:0;}
    50%{
        -webkit-transform:translateY(-8px);
            -moz-transform:translateY(-8px);
            -ms-transform:translateY(-8px);
            transform:translateY(-8px);
            opacity:1;
        }

    100%{opacity:0;}
`;

export const listTextStyle = {
  fontSize: "2rem",
};
