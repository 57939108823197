import { Section, SectionsContainer } from "react-fullpage";
import styled from "styled-components";
import { floatingAnimi } from "../style";
import { options } from "./options";
import { styleData } from "../style";
import { useEffect } from "react";
export const SectionContent = () => {
    useEffect(() => {
        const url = window.location.href.indexOf("#") >= 0 ? true : false;
        console.log(url);
        if (url) {
            window.location.href = "/";
        }
    }, []);
    return (
        <SectionsContainer {...options}>
            {/* First Section Empty */}
            <Section>
                <MainContainer>
                    <video
                        autoPlay
                        playsInline={true}
                        muted
                        loop
                        controls={false}
                        poster="video/core.gif"
                    >
                        <source src="video/core.mp4" type="video/mp4" />
                    </video>
                    <img
                        className="s1-vector-img"
                        src="img/section1/arrow_down.png"
                    />
                </MainContainer>
            </Section>
            <Section>
                <AboutContainer
                    psrc="img/section2/BG.png"
                    msrc="img/section2/m_BG.png"
                >
                    <MiniHead>COREDOT</MiniHead>
                    <AboutHead>
                        <div>COREDOT,</div>
                        <div>a developer owned web3.0 project studio</div>
                    </AboutHead>
                </AboutContainer>
            </Section>
            <Section>
                <AboutContainer
                    psrc="img/section3/BG.png"
                    msrc="img/section3/m_BG.png"
                >
                    <MiniHead>MISSION 1</MiniHead>
                    <AboutHead>
                        <div>We embrace change</div>
                        <div>to create next generation web3.0 projects</div>
                    </AboutHead>
                    <AboutDescript>
                        COREDOT collaborates and incubates with innovative
                        startups to create 360° value everywhere in the web3.0
                        and decentralization. By this joint program, our
                        developers can be a core team member with a motivated
                        incentive scheme.
                    </AboutDescript>
                </AboutContainer>
            </Section>
            <Section>
                <AboutContainer
                    psrc="img/section4/BG.png"
                    msrc="img/section4/m_BG.png"
                >
                    <MiniHead>MISSION 2</MiniHead>
                    <AboutHead>
                        <div>We add technical values</div>
                        <div>to web3.0 projects</div>
                    </AboutHead>
                    <AboutDescript>
                        COREDOT chooses and develops platform services that are
                        building cutting-edge technology applications. By
                        participating as a development partner in the early
                        stages of those projects, we believe that our technical
                        leadership helps them proceed to the next growth stage
                        of business.
                    </AboutDescript>
                </AboutContainer>
            </Section>
            <Section>
                <AboutContainer
                    psrc="img/section5/BG.png"
                    msrc="img/section5/m_BG.png"
                >
                    <MiniHead>MISSION 3</MiniHead>
                    <AboutHead>
                        <div>We build web3.0</div>
                        <div>developer community</div>
                    </AboutHead>
                    <AboutDescript>
                        We are also going to dig deeper into web3.0 and decentralization by building a developer community around COREDOT. Through this, COREDOT will also be a developer community that mainly composes highly skilled software developers who want to be web3.0 builders creating a better internet.
                    </AboutDescript>
                </AboutContainer>
            </Section>
            <Section>
                <AboutContainer
                    psrc="img/section6/BG.png"
                    msrc="img/section6/m_BG.png"
                >
                    <MiniHead>COMMUNITY</MiniHead>
                    <AboutHead>
                        <div>Together, let’s create positive,</div>
                        <div>long-lasting change with OUR DEV COMMUNITY</div>
                    </AboutHead>
                    <AboutDescript>
                        We believe web3.0 builder will be a game changer in
                        every area of our lives and will lead to structural
                        changes in society as a whole.
                    </AboutDescript>
                    <a  href="https://discord.gg/psAEScShpP">
                        <Button>GO KOREA BLOCKCHAIN MEETUP<img src="/img/discord.svg"/> </Button>
                    </a>
                </AboutContainer>
            </Section>
            <Section>
                <AboutContainer
                    psrc="img/section7/BG.png"
                    msrc="img/section7/test.png"
                >
                    <MiniHead>CONTACT</MiniHead>
                    <AboutHead>
                        <div>For any inquiries,</div>
                        <div>
                            please contact
                            <p style={{ textTransform: "lowercase" }}>
                                info@coredot.io
                            </p>
                        </div>
                    </AboutHead>
                    {/* <Button>CONTACT</Button> */}
                    <Footer>
                        COREDOT @2022 Coredotlab Inc. All rights reserved.
                    </Footer>
                </AboutContainer>
            </Section>
        </SectionsContainer>
    );
};
const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const MainContainer = styled(Container)`
    & .s1-img {
        width: 20vw;
        height: 30vh;
    }

    & .s1-vector-img {
        position: absolute;
        top: calc(100vh - 100px);
        z-index: 99;
        cursor: pointer;
        color: inherit;
        animation: ${floatingAnimi} 2s infinite;
    }
    & video {
        height: 422px;
        width: 472px;
    }

  

    @media only screen and (max-width: ${styleData.mobileWidth}) {
        & video {
            width: 280px;
            height: 260px;
        }
    }
`;

const AboutContainer = styled(Container)`
    background-image: url(${(props) => props.psrc});
    background-size: cover;

    @media only screen and (max-width: ${styleData.mobileWidth}) {
        & {
            background-image: url(${(props) => props.msrc});
        }
    }
`;

const MiniHead = styled.div`
    font-size: 18px;
    margin: 2rem;
    color: #969696;

    @media only screen and (max-width: ${styleData.mobileWidth}) {
        & {
            font-size: 15px;
        }
    }
`;
const AboutHead = styled.div`
    text-align: center;
    font-size: 52px;
    margin: 0.2rem;
    color: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    text-transform: uppercase;
    @media only screen and (max-width: ${styleData.mobileWidth}) {
        & {
            font-size: 22px;
        }
    }
`;
const AboutDescript = styled.div`
    margin-top: 1.5rem;
    font-size: 20px;
    color: inherit;
    width: 85%;
    text-align: center;
    line-height: 1.6;
    font-weight: 100;
    @media only screen and (max-width: ${styleData.mobileWidth}) {
        & {
            font-size: 15px;
        }
    }
`;
const Button = styled.button`
    display:flex;
    padding:1rem 1.25rem;
    background: transparent;
    border: 1px solid white;
    color: inherit;
    font-size: 14px;
    margin-top: 50px;
    align-items:center;
    cursor: pointer;
    gap:0.5rem;
  
  @media only screen and (max-width:${styleData.mobileWidth}) {
    & img {
      //display:none;
    }
  } 
`;

const Footer = styled.span`
    color: inherit;
    position: absolute;
    font-size: 14px;
    bottom: 50px;
`;
