import { SectionsContainer, Section, Header, Footer } from "react-fullpage";
import { HeaderContent } from "./components/HeaderContent";
import { SectionContent } from "./components/SectionContent";

function App() {
    console.log(1);
    return (
        <>
            <HeaderContent />
            <SectionContent />
        </>
    );
}
{
    /* <Footer>
<a href="" className="opa">Dcoumentation</a>
<a href="">Example Source</a>
<a href="">About</a>
</Footer> */
}

export default App;
