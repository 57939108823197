import { Header } from "react-fullpage";
import { options } from "./options";
import styled, { css } from "styled-components";
import { Modal } from "./Modal";
import { useState } from "react";

export const HeaderContent = () => {
    const [state, setState] = useState(false);

    return (
        <Header>
            <Container state={state}>
                <Col>
                    <a href={`#${options.anchors[0]}`}>
                        {/* <img src="img/section1/core.png" /> */}
                        <h1
                            style={{
                                fontSize: "25px",
                                fontWeight: "800",
                                marginBottom: "5px",
                            }}
                        >
                            COREDOT
                        </h1>
                    </a>
                </Col>
                <Col>
                    <a href={`#${options.anchors[1]}`}>COREDOT</a>
                    <a href={`#${options.anchors[2]}`}>MISSIONS</a>
                    <a href={`#${options.anchors[5]}`}>COMMUNITY</a>
                    <a href={`#${options.anchors[6]}`}>CONTACT</a>
                </Col>
                <Col>
                    <Modal state={state} setState={setState} />
                </Col>
            </Container>
        </Header>
    );
};

const Container = styled.div`
    background-color: rgb(0, 0, 0, 0.2);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.state
            ? css`
                  display: none;
              `
            : css`
                  display: flex;
              `}
`;
const Col = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    font-weight: 200;

    &:first-child {
        justify-content: flex-start;
        margin-left: 50px;
        & a img {
            width: 150px;
        }
    }
    &:nth-child(2) {
        width: 40%;
        display: flex;
        justify-content: space-evenly;
    }
    &:last-child {
        margin-top: 2px;
        justify-content: end;
        margin-right: 50px;
    }
    & a {
        margin-top: 2px;
    }

    @media only screen and (max-width: 1000px) {
        &:nth-child(2) a {
            display: none;
        }
        &:first-child {
            position: absolute;
            left: -30px;
        }
        &:last-child {
            position: absolute;
            right: -30px;
        }
    }
    @media only screen and (min-width: 1000px) {
        &:last-child * {
            display: none;
        }
    }
`;
